<template>
  <div class="with-nav-bar">
    <van-nav-bar
      left-text="返回"
      title="订单详情"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-cell title="订单编号" :value="state.order_id" />
      <van-cell title="电站名称" :value="state.station_name" />
      <van-cell title="设备名称" :value="state.device_name" />
      <van-cell title="设备编码" :value="state.device_code" />
      <van-cell title="充电枪号" :value="gunName" />
      <van-cell title="充电状态" :value="chargingStatus" />
      <van-cell title="充电时长" :value="chargingTime" />
      <van-cell title="充电电量" :value="totalPower" />
      <van-cell title="订单费用" :value="totalAmount" />
      <van-cell title="订单利润" v-if="state.order_status == 3">
        <template #value>
          <span class="text-success">{{ orderProfit }}</span>
        </template>
      </van-cell>
      <van-cell title="订单创建" :value="createdAt" />
      <van-cell
        v-if="state.order_status == 3"
        title="开始充电"
        :value="startedAt"
      />
      <van-cell
        v-if="state.order_status == 3"
        title="结束充电"
        :value="stoppedAt"
      />
      <van-cell
        v-if="state.order_status == 3"
        title="结束代码"
        :value="state.end_code ? state.end_code : '/'"
      />
      <div
        v-if="state.order_status == 2"
        class="py-4 action-button-area text-center"
      >
        <van-button type="success" @click="settleOrder">结算订单</van-button>
        <p class="text-secondary">
          <small>系统尚未自动结算此订单<br />您可以在此手动结算</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Dialog, Field, Toast } from "vant";
import {
  getChargingOrderDetail,
  settleChargingOrder,
} from "@/api/order.service";
import Moment from "moment";
import { useStore } from "vuex";
Moment.locale("zh-CN");
export default {
  components: {
    [Field.name]: Field,
  },
  setup() {
    let timer1 = null;
    let timer2 = null;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const agent = store.getters.agentInfo;
    const state = reactive({
      loading: true,
      order_id: 0, // 当前订单ID
      now: Math.ceil(new Date().getTime() / 1000), // 系统当前的时间戳
      disableStopCharging: false,
    });

    const loadForm = async (loop = 0) => {
      try {
        const { result } = await getChargingOrderDetail({
          order_id: state.order_id,
          loop,
        });
        for (const key in result) {
          state[key] = result[key];
        }
        clearInterval(timer1);
        clearInterval(timer2);

        // 充电中需每秒递增充电时间
        if (state.order_status == 0 || state.charging_status == 2) {
          timer1 = setInterval(() => {
            state.now = Math.ceil(new Date().getTime() / 1000);
          }, 1000);
        }

        // 充电中 或 手动停止充电后但order_status还是充电中 的情况下 需每5秒刷新订单数据
        if (
          state.order_status == 0 || // 已创建
          state.charging_status == 2 || // 充电中
          (state.order_status == 1 && state.charging_status == 3) || // 手动停止充电
          (state.order_status == 2 && state.end_code === null) // 已停止但未拔枪
        ) {
          timer2 = setInterval(() => {
            loadForm(1);
          }, 5000);
        }

        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
        setTimeout(() => {
          router.back();
        }, 2000);
      }
    };

    const gunName = computed(() => {
      const names = ["主枪", "A枪", "B枪"];
      if (state.gun_number < 0 || state.gun_number > 2) {
        return "未知";
      }
      return names[state.gun_number];
    });

    const chargingTime = computed(() => {
      if (state.order_status == 1 && state.charging_status == 2) {
        const difftime = state.now - state.charging_started_at;
        const isotime = Moment.duration(difftime, "seconds").toISOString();
        return isotime
          .replace(/(P|T)?/gi, "")
          .replace("D", "天")
          .replace("H", "时")
          .replace("M", "分")
          .replace("S", "秒");
      }
      if (state.order_status == 2 || state.order_status == 3) {
        const isotime = Moment.duration(
          state.actual_charging_time,
          "seconds"
        ).toISOString();
        return isotime
          .replace(/(P|T)?/gi, "")
          .replace("D", "天")
          .replace("H", "时")
          .replace("M", "分")
          .replace("S", "秒");
      }
      return "/";
    });

    const totalPower = computed(() => {
      const power = new Number(state.total_power * 0.01).toFixed(2);
      return `${power}kW·h`;
    });

    const totalAmount = computed(() => {
      const amount = new Number(state.total_amount * 0.01).toFixed(2);
      return `${amount}元`;
    });

    const chargingStatus = computed(() => {
      if (
        state.order_status < 0 ||
        state.order_status > 4 ||
        state.charging_status < 0 ||
        state.charging_status > 7
      ) {
        return "未知";
      }
      const workStatus = [
        "空闲中",
        "准备中",
        "充电中",
        "已结束",
        "启动失败",
        "已预约",
        "系统故障",
        "被占用",
      ];
      const orderStatus = [
        "启动中", // 已创建
        "充电中",
        "已停止",
        "已完成",
        "已关闭",
      ];
      if (state.order_status == 1) {
        return workStatus[state.charging_status];
      } else {
        return orderStatus[state.order_status];
      }
    });

    const orderProfit = computed(() => {
      if (!agent) {
        return "无权限"; // 如果是分走电费的用户查看时候不可看利润
      }
      let profit = 0;
      if (agent.level == 1) {
        profit = state.l1_profit;
      } else if (agent.level == 2) {
        profit = state.l2_profit;
      }
      return `${new Number(profit * 0.01).toFixed(2)}元`;
    });

    const formatDateTime = (time) => {
      return Moment(time * 1000).format("YYYY-MM-DD HH:mm:ss");
    };

    const createdAt = computed(() => {
      if (state.created_at) {
        return formatDateTime(state.created_at);
      }
      return "/";
    });

    const startedAt = computed(() => {
      if (state.charging_started_at) {
        return formatDateTime(state.charging_started_at);
      }
      return "/";
    });

    const stoppedAt = computed(() => {
      if (state.charging_stopped_at) {
        return formatDateTime(state.charging_stopped_at);
      }
      return "/";
    });

    const settleOrder = () => {
      const message =
        state.charging_stopped_at &&
        state.charging_stopped_at + 86400 >
          Math.floor(new Date().getTime() / 1000)
          ? `系统建议在${Moment(
              (state.charging_stopped_at + 86400) * 1000
            ).format(
              "YYYY-MM-DD HH:mm:ss"
            )}后才手动结算, 是否继续以当前数据结算订单?`
          : "请确认是否手动结算订单?";
      Dialog.confirm({
        title: "结算订单",
        message,
      })
        .then(() => {
          processSettle();
        })
        .catch(() => {});
    };

    const processSettle = async () => {
      Toast.loading({
        message: "正在结算",
        duration: 0,
        forbidClick: true,
      });
      try {
        await settleChargingOrder(state.order_id);
        Toast.success("结算成功");
        loadForm();
      } catch (error) {
        loadForm();
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      const { order_id } = route.query;
      if (!order_id) {
        Toast.fail("参数错误");
        setTimeout(() => {
          router.back();
        }, 2000);
      } else {
        state.order_id = order_id;
        loadForm();
      }
    });

    onUnmounted(() => {
      clearInterval(timer1);
      clearInterval(timer2);
    });

    return {
      state,
      loadForm,
      gunName,
      chargingTime,
      totalPower,
      totalAmount,
      chargingStatus,
      createdAt,
      startedAt,
      stoppedAt,
      settleOrder,
      orderProfit,
    };
  },
};
</script>
