import ApiService from "./api.service";

export function getChargingOrderLog(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/order/list", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getChargingOrderDetail(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/order/detail", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function settleChargingOrder(order_id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/order/settle", { order_id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
